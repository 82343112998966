<template>
  <b-overlay :show="isFetchingCmsSites || isFlushingSitesCache">
    <b-card class="mb-4">
      <b-row>
        <b-col class="text-right mb-2">
          <b-button-group>
            <b-button variant="danger" disabled @click="onFlushCache"
              >Flush cache</b-button
            >
            <b-button
              variant="success"
              @click="$bvModal.show('cms-create-site')"
              >New site</b-button
            >
          </b-button-group>
        </b-col>
      </b-row>
      <b-table :items="cmsSites" :fields="fields" responsive>
        <template #cell(#)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(logoSrc)="data">
          <b-img thumbnail :src="data.value" width="120px"></b-img>
        </template>
        <template #cell(agentId)="data">
          <div>
            {{ mapAgent(data.value).name }}
          </div>
        </template>
        <template #cell(isDevelopment)="data">
          <b-badge v-if="data.value" variant="warning">development</b-badge>
          <b-badge v-else variant="success">production</b-badge>
        </template>
        <template #cell(id)="data">
          <div>
            <b-button-group>
              <b-button
                v-if="!data.item.isDevelopment && !hasDevSite(data.value)"
                variant="warning"
                size="sm"
                @click="onEnableDevelopment(data.value)"
                >Enable development</b-button
              >
              <b-button
                variant="primary"
                type="button"
                size="sm"
                @click="onEditSite(data.value)"
                >Edit</b-button
              >
              <b-button
                v-if="data.item.isDevelopment"
                variant="danger"
                size="sm"
                @click="onDeleteDevSite(data.value)"
                >Remove</b-button
              >
            </b-button-group>
          </div>
        </template>
        <template #empty="">
          <p class="text-center text-muted">{{
            $t('messages.nothing_here')
          }}</p>
        </template>
      </b-table>
    </b-card>
    <CmsCreateSite />
  </b-overlay>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  components: {
    CmsCreateSite: () => import('./create-site.vue'),
  },
  data() {
    return {
      fields: [
        '#',
        {
          key: 'logoSrc',
          label: 'Logo',
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'siteName',
          label: 'Site',
        },
        {
          key: 'agentId',
          label: 'Agent',
        },
        {
          key: 'isDevelopment',
          label: 'Mode',
        },
        {
          key: 'id',
          label: 'Actions',
          class: 'text-right',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      isFetchingCmsSites: (state) => state.cms.isFetchingCmsSites,
      cmsSites: (state) => state.cms.cmsSites,
      isFlushingSitesCache: (state) => state.cms.isFlushingSitesCache,
    }),
    ...mapGetters(['userInfo']),
    masterSiteIds() {
      return this.cmsSites
        .filter((site) => site.cmsSiteMasterId)
        .map((site) => site.cmsSiteMasterId)
    },
    agents() {
      const agents = []
      for (const master of this.userInfo.masters) {
        agents.push(...master.children)
      }
      return agents
    },
  },
  created() {
    this.fetchCmsSites()
  },
  methods: {
    ...mapActions([
      'fetchCmsSites',
      'flushSitesCache',
      'enableDevelopment',
      'deleteDevSite',
    ]),
    hasDevSite(id) {
      const found = this.masterSiteIds.find((siteId) => siteId === id)
      if (found) {
        return true
      }
      return false
    },
    onEditSite(siteId) {
      this.$router.push(`/cms/detail/${siteId}`)
    },
    onFlushCache() {
      this.$bvModal
        .msgBoxConfirm(
          'Flushing cache might effect web performance for a while. 🙅🏻 🙅‍♀️ 🙅‍♂️'
        )
        .then((value) => {
          if (value) {
            this.flushSitesCache()
          }
        })
    },
    onEnableDevelopment(siteId) {
      this.enableDevelopment(siteId)
    },
    onDeleteDevSite(siteId) {
      this.$bvModal.msgBoxConfirm('Are you sure?').then((value) => {
        if (value) {
          this.deleteDevSite(siteId)
        }
      })
    },
    mapAgent(agentId) {
      return (
        this.agents?.find((agent) => agent.id === agentId) || { name: 'N/A' }
      )
    },
  },
}
</script>
